@each $name, $color in ($colors-message) {
  .message-#{$name} {
    font-size: calc-rem(14px);
    color: $color-white;
    background-color: $color;
    margin-top: calc-rem(4px);
    margin-bottom: calc-rem(12px);

    .message-link {
      text-decoration: underline;
      color: $color-white;
    }
  }

  .input-message-#{$name} {
    color: $color;
    @extend .text-xs !optional;
  }
}