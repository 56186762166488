.divider {
    position: relative;
    margin: calc-rem(30px) 0;
    display: flex;
    align-items: center;
    justify-content: center;
  
    span {
      display: inline-block;
      color: $color-white;
      border: 1px solid $color-white;
      padding: calc-rem(5px);
      background-color: $color-blue-dark;
    }
  
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-white;
      left: 0;
      top: calc(50% - 0.5px);
      z-index: -1;
    }
  }