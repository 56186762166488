.thumbnail__user {
    background-color: $color-gray-3;
    border-radius: $border-radius-xxl;
    box-shadow: calc-rem(3px) calc-rem(5px) calc-rem(9px) rgba($color-black, 0.25);
    padding-bottom: 85%;
    position: relative;
    overflow: hidden;

    img, 
    .svg-inline {
        @include absolute-fullsize;
        height: 100%;
    }
    
    .svg-inline {
        width: 100%;
        height: 100%;
        padding: calc-rem(10px);
        fill: rgba($color-black, 0.3);
    }
}