$table-cell-padding:          calc-rem(12px);
$table-cell-padding-sm:       math.div($table-cell-padding, 2);

$table-bg:                    null;
$table-color:                 $color-gray;

$table-border-color:          $table-color;
$table-border-width:          2px;

$table-head-bg:               $color-primary;
$table-head-color:            $color-white;

$table-striped-order:         odd;