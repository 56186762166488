// include the size (ex: .icon-sm) along with the specific icon class ex: .icon-arrow-white
.icon {
  &-blank {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &::after {
      content: "";
      @include absolute-center-center;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

	@each $name, $size in $icon-size {
		&-#{$name} {
			@extend .icon-blank;
			width: $size;
			height: $size;
		}
	}

  @each $key, $rotation in $icon-rotate {
    &-#{$key} {
      transform: rotate(#{$rotation});
    }
  }
}

// ICON SOCIAL MEDIA
.icon-social {
  &-yt-white::after {
    background-image: get-icon("youtube", $color-white);
  }

  &-li-white::after {
    background-image: get-icon("linkedin", $color-white);
  }

  &-tw-white::after {
    background-image: get-icon("twitter", $color-white);
  }
}

// Car Ride
.icon-car-ride::after {
  background-image: $icon-car-ride;
}