@mixin create-link-color(
  $color: $color-primary,
  $hover-color: $color-black,
) {
  color: $color;

  &:hover {
    color: $hover-color;
  }
}

$arrow-base: 16px;

@mixin link-arrow(
  $width: $arrow-base,
  $height: $arrow-base,
  $rotate: 0,
  $color: $color-primary,
  $icon: "chevron"
) {
  padding-left: calc-rem(20px);

  &::before {
    content: "";
    display: inline-block;
    width: $width;
    height: $height;
    margin: calc-rem(-4px) calc-rem(10px) 0 calc-rem(-20px);
    vertical-align: middle;
    background: get-icon($icon, $color) no-repeat center;
    transform: rotate($rotate);
  }
}

a {
	display: inline-block;
	font-size: calc-rem(14px);
	font-weight: 600;
	position: relative;
  @include create-link-color($color-white, $color-primary);
  @include transition(color, 0.3s);

  &.link-arrow {
    @include link-arrow(12px, 12px, 90deg);
  }
}

.link-register {
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin-top: calc-rem(32px);
}
