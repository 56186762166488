body {
  font: 400 $font-size-base $font-lato;
  background-color: $color-blue-dark;
  margin-bottom: calc-rem(84px);
  overflow-x: hidden;

  * {
    box-sizing: border-box;
  }
}

// html, body {
//   height: 100%;
// }