﻿// TRIPLE LINE MENU BUTTON
.menu-toggle {
    display: none;
    position: relative;
    width: calc-rem(30px);
    height: calc-rem(30px);
    margin-left: auto;
    background-color: transparent;
    border: none;
    z-index: 10;

    &-inner {
        position: relative;
        background-color: $color-secondary;
        height: calc-rem(4px);
        width: calc-rem(20px);
        @include transition((width, background-color), .25s);

        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 0;
            height: calc-rem(4px);
            width: calc-rem(30px);
            background-color: $color-secondary;
            @include transition((background-color, top, transform), .25s);
        }

        &::before {
            top: calc-rem(-12px);
        }

        &::after {
            top: calc-rem(12px);
        }
    }

    &:hover {
        .menu-toggle-inner {
            background-color: $color-blue-light-1;

            &::before,
            &::after {
                background-color: $color-blue-light-1;
            }
        }
    }

    &-open {
        .menu-toggle-inner {
            width: 0;
            background-color: $color-white;
            
            &::before,
            &::after {
                top: 0;
                background-color: $color-white;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }
    }

    @include media-breakpoint-down("lg") {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}