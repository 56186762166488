$color-black: #000;
$color-white: #fff;

$color-primary: #73e5aa;
$color-secondary: #003F6F;

$color-blue-dark: #353744;
$color-blue-dark-2: #3A3A46;
$color-blue-dark-3: #2a2d39;
$color-blue-light-1: #69ABDC;
$color-blue-light-2: #C7D7E3;
$color-purple-1: #6F64C5;
$color-purple-2: #7284E9;
$color-green-1: #004B4F;
$color-green-2: #2ECACE;
$color-green-3: #016a70;
$color-red: #d52a6b;
$color-pink: #E87590;
$color-brown: #3B1A00;
$color-gray: #3F3F3F;
$color-gray-2: #43485B;
$color-gray-3: #585E76;
$color-dark-gray: #252525;
$color-light-gray: #8a8989;
$color-light-gray-2: #c9c9c9;
$color-light-gray-3: #eeeef0;

$color-tags: lighten($color-blue-light-1, 27%);

// Information
$color-error: $color-red;
$color-warning: $color-pink;
$color-success: $color-green-2;
$color-info: $color-secondary;

$colors: (
    'primary': $color-primary,
    'secondary': $color-secondary,
    'black': $color-black,
    'white': $color-white,
    'blue': $color-blue-light-1,
    'brown': $color-brown,
    'gray': $color-gray,
    'gray-light': $color-light-gray,
    'error': $color-error,
    'warning': $color-warning,
    'success': $color-success,
    'info': $color-info,
);

$colors-message: (
    error: $color-error,
    warning: $color-warning,
    success: $color-success,
    info: $color-info,
);