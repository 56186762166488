// button width
$btn-font-sm: calc-rem(12px);
$btn-font-md: calc-rem(14px);
$btn-padding-sm: calc-rem(8px) 2rem;
$btn-padding-md: calc-rem(14px) 2rem;
$btn-padding-lg: calc-rem(22px) 2rem;
$btn-height-sm: calc-rem(37px);
$btn-height-md: calc-rem(48px);
$btn-height-lg: calc-rem(64px);
$btn-icon-spacing: calc-rem(16px);
$btn-icon-margin: calc-rem(10px);
$btn-icon-margin-20: calc-rem(20px);