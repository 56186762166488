$icon-size: (
	xs: 16px,
	sm: 24px,
	md: 30px,
	lg: 40px
);

$icon-rotate: (
  90: 90deg,
  180: 180deg,
  270: 270deg
);

$data-svg-prefix: "data:image/svg+xml;utf-8,";
$icons: (
  // SOCIAL ICONS
  "facebook":
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path fill="%%COLOR%%" d="M0,18C0,8.1,8.1,0,18,0s18,8.1,18,18s-8.1,18-18,18S0,27.9,0,18z M19.9,28.6v-9.8h2.7l0.4-3.4h-3.1l0-1.7 c0-0.9,0.1-1.4,1.3-1.4h1.7V9h-2.7c-3.2,0-4.4,1.6-4.4,4.4v2h-2v3.4h2v9.8H19.9z"/></svg>',
  "youtube":
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><path fill="%%COLOR%%" d="M0,14C0,6.3,6.3,0,14,0s14,6.3,14,14s-6.3,14-14,14S0,21.7,0,14z M21.2,10.5c-0.2-0.7-0.7-1.2-1.3-1.4 C18.7,8.9,14,8.9,14,8.9s-4.7,0-5.8,0.3C7.5,9.4,7,9.9,6.8,10.5c-0.3,1.2-0.3,3.7-0.3,3.7s0,2.5,0.3,3.7c0.2,0.7,0.7,1.2,1.3,1.4 c1.2,0.3,5.8,0.3,5.8,0.3s4.7,0,5.8-0.3c0.6-0.2,1.1-0.7,1.3-1.4c0.3-1.2,0.3-3.7,0.3-3.7S21.5,11.7,21.2,10.5 M12.6,16.8v-4.7 l3.7,2.3L12.6,16.8z"/></svg>',
  "linkedin":
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><path fill="%%COLOR%%" d="M14,0C6.3,0,0,6.3,0,14s6.3,14,14,14s14-6.3,14-14S21.7,0,14,0z M9.9,21.1H6.7v-9.5h3.2V21.1z M8.3,10.2 c-1.1,0-1.8-0.7-1.8-1.6C6.5,7.7,7.2,7,8.3,7c1.1,0,1.8,0.7,1.8,1.6C10.1,9.5,9.4,10.2,8.3,10.2z M21.4,21.2h-3.2V16 c0-1.3-0.5-2.2-1.6-2.2c-0.9,0-1.4,0.6-1.6,1.2c-0.1,0.2-0.1,0.5-0.1,0.8v5.3h-3.2V21c0-0.5,0-3,0-5.3v-0.9c0-0.3,0-0.5,0-0.8v-0.5 c0-1,0-1.7,0-1.9h3.2V13c0.3-0.7,1.1-1.6,2.8-1.6c2.1,0,3.7,1.4,3.7,4.3V21.2z"/></svg>',
  "twitter":
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><path fill="%%COLOR%%" d="M0,14C0,6.3,6.3,0,14,0s14,6.3,14,14s-6.3,14-14,14S0,21.7,0,14z M13.6,11.4l0,0.5l-0.5-0.1 c-1.8-0.2-3.3-1-4.7-2.3L7.8,8.9L7.6,9.3c-0.4,1.1-0.1,2.2,0.6,2.9c0.4,0.4,0.3,0.5-0.4,0.2c-0.2-0.1-0.4-0.1-0.5-0.1 c-0.1,0.1,0.2,1,0.4,1.3c0.3,0.5,0.8,1,1.3,1.3l0.5,0.2l-0.6,0c-0.5,0-0.6,0-0.5,0.2c0.2,0.6,1,1.3,1.8,1.6l0.6,0.2l-0.5,0.3 c-0.8,0.5-1.7,0.7-2.6,0.7c-0.4,0-0.8,0-0.8,0.1c0,0.1,1.2,0.7,1.9,0.9c2.1,0.6,4.6,0.4,6.4-0.7c1.3-0.8,2.6-2.3,3.3-3.8 c0.3-0.8,0.7-2.3,0.7-3c0-0.5,0-0.5,0.6-1.1c0.3-0.3,0.6-0.7,0.7-0.8c0.1-0.2,0.1-0.2-0.4,0c-0.8,0.3-0.9,0.3-0.5-0.2 c0.3-0.3,0.7-0.9,0.7-1.1c0,0-0.1,0-0.3,0.1C19.7,8.8,19.3,9,19,9.1l-0.5,0.2L18,8.9c-0.3-0.2-0.6-0.4-0.8-0.4 c-0.5-0.1-1.3-0.1-1.7,0C14.3,9,13.5,10.1,13.6,11.4z"/></svg>',
);

$icon-car-ride: url('../../../icons/car-icon.svg');
$icon-direction: url('../../../icons/direction.svg');
$half-circle-stamp: path('M0,24h12V12C12,18.6,6.6,24,0,24z M0,0c6.6,0,12,5.4,12,12V0H0z');