// COLOR GENERATOR
@each $name, $color in ($colors) {
  .text-#{$name} {
    color: $color;
  }

  .fill-#{$name} {
    fill: $color;
  }
}

p {
  font: 400 1rem $font-montserrat;
  line-height: calc-rem(32px);
  color: $color-black;

  @include media-breakpoint-down(md) {
    font-size: calc-rem(14px);
    line-height: calc-rem(26px);
  }
}

.text {
  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-initial {
    text-transform: initial;
  }

  &-bold {
    font-weight: 700;
  }
}

// TEXT SIZES
.text {
  &-xxs {
    font-size: calc-rem(10px);
    line-height: calc-rem(12px);
  }

  &-xs {
    font-size: calc-rem(12px);
    line-height: calc-rem(16px);
  }

  &-sm {
    font-size: calc-rem(14px);
    line-height: calc-rem(18px);
  }

  &-lg {
    font-size: calc-rem(20px);
    line-height: calc-rem(24px);
  }
}