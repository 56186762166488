// Table template
@mixin table-template($border: null, $bg: $table-bg) {
  width: 100%;
  margin-bottom: 1rem;
  color: $table-color;
  background-color: $bg; // Reset for nesting within parents with `background-color`.
  table-layout: fixed;

  thead th {
    background-color: $table-head-bg;
    color: $table-head-color;
    font-weight: 700;
    line-height: calc-rem(20px);
  }

  tbody td {
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
  }

  tr {
    td,
    th {
      font-size: calc-rem(12px);
      padding: $table-cell-padding;
      border: none;
    }

    @if $border != null {
      td {
        border-bottom: $table-border-width solid $border;
      }
    }

    &:hover {
      background-color: rgba($table-head-bg, 0.2);
    }
  }
}
