// GLOBAL TEXT-LIKE INPUTS
%global-field-style {
    width: 100%;
    color: rgba($color-white, .85);
    background-color: $color-blue-dark-3;
    border: 1px solid transparent;
    border-radius: $border-radius-xxl;
    padding: calc-rem(16px);
		margin: calc-rem(4px) 0;
    font-family: inherit;
    font-size: calc-rem(14px);
    outline: none;
    cursor: text;
    touch-action: manipulation;
    box-shadow: inset 0 4px 0.5px rgba($color-black, .25);
    @include transition(all, 0.3s);
  
    &.error {
      border-color: $color-error;
    }
  
    &.success {
      border-color: $color-success;
    }
  
    &[readonly] {
      background-color: $color-light-gray;
      color: darken($color-dark-gray, 10%);
    }

		&[disabled],
		&.disabled {
			font-weight: 700;
			border: none;
			color: $color-gray-3;
			background-color: $color-light-gray-2;	
		}
  
    &.floating-label {
      & + label {
        max-width: 66.66%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(calc-rem(16.5px), calc-rem(40px));
        cursor: text;
      }
      /* By default, the placeholder should be transparent. Also, it should 
          * inherit the transition. */
      @include input-placeholder {
        color: $color-dark-gray;
        font-weight: 700;
        opacity: 0;
        transition: inherit;
      }
      /* Show the placeholder when the input is focused. */
      &:focus {
        @include input-placeholder {
          opacity: 1;
        }
      }
      /* When the element is focused, remove the label transform.
          * Also, do this when the placeholder is _not_ shown, i.e. when 
          * there's something in the input at all. */
      &:focus + label,
      &:not(:placeholder-shown) + label,
      &:-webkit-autofill + label {
        transform: translate(0, 0);
        cursor: pointer;
      }
    }
  }
  
  // RADIO AND CHECKBOX BASE
  @mixin radio-checkbox($box-width, $radius) {
    .input-wrapper {
      display: flex;
      align-items: center;
      min-height: calc-rem(46px);
    }
  
		& {
			z-index: 0;
		}
  
    label {
      position: relative;
      padding-left: calc-rem(44px);
      margin: 0;
      color: $color-white;
      font-weight: 400;
      font-size: calc-rem(14px);
      cursor: pointer;
  
      &::before {
        content: "";
        position: absolute;
        box-sizing: border-box;
        z-index: 1;
        @include transition(transform, 0.25s);
      }
  
      &::after {
        content: "";
        @include absolute-left-center;
        border-radius: $radius;
        width: $box-width;
        height: $box-width;
        background-color: transparent;
        border: 1px solid $color-white;
        box-sizing: border-box;
        @include transition(all, 0.25s);
      }
    }
  
    input[type="radio"],
    input[type="checkbox"] {
      display: none;
      opacity: 0;
  
      &[disabled] + label {
        cursor: not-allowed;
        color: lighten($color-gray, 26%);
  
        &::after {
          border-color: lighten($color-gray, 26%);
        }
      }
    }
  }