// AUTOFILL STYLE
input,
textarea,
select {
  &:-webkit-autofill {
    &,
    &:hover,
    &:focus {
      -webkit-text-fill-color: $color-white;
      box-shadow: 0 0 0px 1000px rgba($color-primary, .5) inset;
      @include transition(background-color, 5000s);
    }
  }
}