.footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: get-variable("sm", $container-max-widths);
  transform: translateX(-50%);
  border-top-left-radius: $border-radius-footer;
  border-top-right-radius: $border-radius-footer;
  background-color: $color-gray-2;
  box-shadow: 0 calc-rem(-4px) calc-rem(4px) rgba($color-black, 0.25);
  z-index: 900;
  
  &__nav {
    padding: calc-rem(12px) calc-rem(35px);

    ul {
      justify-content: space-between;
    }

    a {
      @extend .btn-icon-center;
      height: calc-rem(44px);
      width: calc-rem(44px);
      background: $color-blue-dark;
      border-radius: $border-radius-circle;
      cursor: pointer;
      
      .svg-inline {
        fill: $color-gray-2;
        font-size: calc-rem(20px);
      }
      
      &.active {
        box-shadow: 0 calc-rem(4px) calc-rem(4px) rgba($color-black, 0.36);

        .svg-inline {
          fill: $color-primary;
        }
      }
    }
  }
}