.react-select-wrapper {
    .react-select {
        &__control {
            background-color: $color-blue-dark-3;
            border: 1px solid transparent;
            border-radius: $border-radius-xxl;
            min-height: calc-rem(50px);
						margin: calc-rem(4px) 0;
            box-shadow: inset 0 4px 0.5px rgba($color-black, .25);
        }
    
        &__single-value {
            color: $color-white;
        }
    
        &__input[type="text"] {
            box-shadow: none;
        }

        &__input-container {
            color: $color-white;
        }

				&__option--is-selected {
					background-color: $color-green-2;
				}
    }
}