@mixin absolute-center-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-right-center {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-left-center {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-fullsize {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

//Mixin get specific variable
@function get-variable($var, $object) {
  @return map-get($object, $var);
}

// Retina
@mixin retina($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* Serving 2x image on Retina display */
    background-image: url($image);
    background-size: $width $height;
  }
}

// TRANSITION GENERATOR
@mixin transition(
  $property: all,
  $duration: 0.3s,
  $easing: ease-in-out,
  $delay: 0s
) {
	& {
		transition-property: $property;
		transition-duration: $duration;
		transition-timing-function: $easing;
		transition-delay: $delay;
	}

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

// INPUT PLACEHOLDER
@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

%background-pseudo-text {
	&::before {
    content: attr(data-bg-text);
    position: absolute;
    top: calc-rem(42px);
    font-size: 16vW;
    color: rgba($color-white, .03);
    font-family: $font-lato;
    font-weight: 700;
    max-width: calc(100% - calc-rem(50px));
    overflow: hidden;
    z-index: -1;
  }
}