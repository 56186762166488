.table {
  &-regular {
    @include table-template;
  }
  
  // Table versions
  &-striped {
    @include table-template;
  
    tr:nth-of-type(#{$table-striped-order}) {
      background-color: rgba($table-color, .2);
    }
  
    td {
      border-top: math.div($table-border-width, 2) solid $border-color;
    }
  }
  
  &-bordered {
    @include table-template($table-border-color);
  }
  
  //Overflows
  &-overflow-x {
    overflow-x: auto;
  }
  
  &-overflow-y {
    max-height: calc-rem(500px);
    overflow-y: auto;
  }
  
  // Condensed table w/ half padding
  &-sm {
    th,
    td {
      padding: $table-cell-padding-sm;
    }
  }
}