// REM CONVERTER
@function calc-rem($size) {
  $remSize: math.div($size, $font-size-base);
  @return $remSize * 1rem;
}

// FONT FACE GENERATOR
@mixin font-face(
  $font-name,
  $file-name,
  $weight: normal,
  $style: normal,
  $font-display: swap
) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + ".eot");
    src: url($file-name + ".eot?#iefix") format("embedded-opentype"),
      url($file-name + ".ttf") format("truetype"),
      url($file-name + ".woff2") format("woff2"),
      url($file-name + ".woff") format("woff");
    font-weight: $weight;
    font-style: $style;
    font-display: $font-display;
  }
}

// TRUNCATE TEXT (ELLIPSIS)
@mixin truncate-text($overflow: ellipsis) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow;
}

// REPLACE STRING
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// Reset text
@mixin reset-text() {
  font-family: $font-montserrat;
  // We deliberately do NOT reset font-size or word-wrap.
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
}
