form {
	margin: calc-rem(16px) 0;
}

.form {
	&-group {
		display: flex;
		flex-flow: column;
	}

	&-field {
		margin-bottom: calc-rem(24px);

		&--disabled {
			label {
				color: lighten($color-light-gray, 15%)
			}
		}

		&__icon {
			position: relative;

			.svg-inline {
				position: absolute;
				left: calc-rem(20px);
				top: 50%;
				transform: translateY(-50%);
				fill: $color-primary;
				pointer-events: none;
			}

			input {
				padding-left: calc-rem(56px);
			}
		}
	}

	&-footer {
		margin-top: calc-rem(25px);
		align-items: center;
	}
}