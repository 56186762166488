.not-found {
	height: calc(100vH - calc-rem(192px));

	&__main {
		@extend %background-pseudo-text;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;

		&::before {
			top: 32%;
		}
	}

	main {
		height: 100%;
	}
}