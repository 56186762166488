.ride-details {
	@extend %background-pseudo-text;

	// &::before {
	// 	left: 46%;
	// 	width: 54%;
	// 	overflow: hidden;
	// }

	&__driver {
		padding: 0 calc-rem(14px);
		margin-bottom: calc-rem(48px);
	}

	&__photo {
		border-radius: $border-radius-lg;
		padding-bottom: 95%;
	}

	&__info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;

		p,
		h3 {
			font-family: $font-lato;
			font-weight: 700;
		}

		p {
			display: flex;
			align-items: center;
			font-size: calc-rem(12px);
			color: $color-light-gray-2;
			margin-bottom: calc-rem(12px);

			.svg-inline {
				font-size: calc-rem(14px);
				fill: $color-light-gray-2;
				margin-right: calc-rem(16px);
			}
		}

		h3 {
			color: $color-light-gray-3;
			font-size: calc-rem(20px);
			line-height: calc-rem(22px);
			margin-top: calc-rem(6px);
		}
	}

	&__save-driver {
		position: absolute;
		top: 0;
		right: 0;
	}

	&__btn-save::before {
		content: '';
		position: absolute;
		top: calc-rem(10px);
		right: calc-rem(10px);
		width: calc-rem(4px);
		height: calc-rem(4px);
		border-radius: 50%;
		background-color: rgba($color-primary, .5);
		transform: scale(1);
		animation: pulse 1.5s 1;
	}

	&__save-icon {
		font-size: calc-rem(24px);
		fill: $color-light-gray-3;

		&--active {
			fill: $color-primary;
		}
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	70% {
		transform: scale(14);
	}

	100% {
		background-color: rgba($color-primary, 0);
	}
}