@mixin gradient-striped($color: rgba($color-white, 0.15), $angle: 45deg) {
  background-image: linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
}

@keyframes progress-bar-stripes {
  from { background-position: $progress-height 0; }
  to { background-position: 0 0; }
}

.progress {
  display: flex;
  height: $progress-height;
  overflow: hidden; // force rounded corners by cropping it
  font-size: $progress-font-size;
  background-color: $progress-bg;
  border-radius: $progress-border-radius;
  box-shadow: $progress-box-shadow;

    &-bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      color: $progress-bar-color;
      background-color: $progress-bar-bg;
      text-align: center;
      white-space: nowrap;
      transition: $progress-bar-transition;
    }
    
    &-bar-striped {
      @include gradient-striped();
      background-size: $progress-height $progress-height;
    }
    
    &-bar-animated {
      animation: progress-bar-stripes $progress-bar-animation-timing;
      
      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
    }
}
