.react-tabs__tab {
    flex: 1;
    padding: calc-rem(20px);
    color: $color-white;
    background-color: $color-blue-dark-2;
    font-family: $font-montserrat;
    font-size: calc-rem(14px);
    font-weight: 700;
    text-align: center;
    border-radius: $border-radius-md;
    cursor: pointer;

    &--selected {
        background: transparent;
    }

    &-list {
        display: flex;
        align-items: center;
    }
}