$zindex-tooltip:                    1070;
$tooltip-max-width:                 calc-rem(200px);
$tooltip-color:                     $color-white;
$tooltip-bg:                        $color-black;
$tooltip-opacity:                   .9;
$tooltip-padding-y:                 calc-rem(4px);
$tooltip-padding-x:                 calc-rem(8px);
$tooltip-margin:                    0;

$tooltip-arrow-width:               .8rem;
$tooltip-arrow-height:              .4rem;
$tooltip-arrow-color:               $tooltip-bg;