﻿.filters {
	position: relative;
	margin: 0 calc-rem(-6px);
	z-index: 10;

	form {
		margin: calc-rem(8px) 0 calc-rem(40px);
	}

	textarea,
	input[type="text"],
	input[type="search"],
	input[type="email"],
	input[type="password"],
	input[type="number"],
	input[type="tel"] {
		padding-top: calc-rem(10px);
		padding-bottom: calc-rem(10px);
	}

	.form-field {
		margin-bottom: calc-rem(14px);
	}

	.react-select {
		&__control {
			min-height: calc-rem(38px);
		}
	}

	&__route {
		position: relative;
		display: flex;
		align-items: center;
		z-index: 10;

		label {
			display: none;
		}
	}

	&__autocomplete {
		max-width: calc-rem(124px);
		box-shadow: 0 calc-rem(4px) calc-rem(4px) rgba($color-black, 0.25);
		border-radius: $border-radius-lg;
		font-size: calc-rem(13px);
		line-height: calc-rem(16px);
		background-color: $color-blue-dark-3;

		.svg-inline {
			left: calc-rem(12px);
		}

		input {
			padding-left: calc-rem(36px);
			background-color: transparent;
			border-radius: 0;
			box-shadow: none;
		}
	}

	&__additional {
		margin: calc-rem(16px) 0 calc-rem(32px);

		label {
			flex: 1;
			font-size: calc-rem(14px);
			line-height: calc-rem(16px);
			font-weight: 400;
		}

		.react-select-wrapper {
			flex: 1;
			max-width: calc-rem(160px);
			font-size: calc-rem(14px);
		}
	}

	&__field {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__dash {
		flex: 1;
		height: 1px;
		border-bottom: 1px dashed rgba($color-white, 0.6);
		margin: 0 calc-rem(12px) calc-rem(14px);
	}

	&__more {
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin: calc-rem(16px) 0;
	}

	&__button {
		@extend .btn-stripped, .btn-icon-left;
		padding-left: calc-rem(28px);

		.svg-inline {
			left: 0;
			fill: $color-primary;
			font-size: calc-rem(22px);
		}

		&:hover {
			color: $color-light-gray;

			.svg-inline {
				fill: darken($color-primary, 20%);
			}
		}
	}
}