.map {
  &__wrapper {
    position: relative;
		padding: calc-rem(6px);
  }

	&__inner {
		border-radius: calc-rem(12px);
		overflow: hidden;
    box-shadow: 0px 0px 0.5px 6px rgba($color-black, 0.25);
	}

  &__distance {
    position: relative;
    padding: calc-rem(12px) calc-rem(6px);
    color: $color-white;
    background: linear-gradient(169.12deg, $color-green-2 -80%, $color-green-3 100%);
    text-align: center;
    margin-top: calc-rem(-20px);
  }
  
  &__route {
    margin: calc-rem(16px) 0;
  }
}

.gm-style .gm-style-iw-c {
  overflow: visible;
  box-shadow: 0 4px 10px 2px rgba(0, 0, 0, .35);
}

.gm-style-iw {
  &-c {
    padding: calc-rem(20px) !important;
    max-width: calc-rem(280px) !important;
  }

  &-d {
    overflow: initial !important;
  }
}

.gm-ui-hover-effect {
  opacity: 1;
  border-radius: 50%;
  background-color: $color-white !important;
  top: calc-rem(-10px) !important;
  right: calc-rem(-6px) !important;
  width: calc-rem(36px) !important;
  height: calc-rem(36px) !important;

  span {
    width: calc-rem(20px) !important;
    height: calc-rem(20px) !important;
  }
}

.info-window {
  &__content {
    p {
      line-height: calc-rem(22px);
    }
  }

  &__main {
    border-bottom: 2px solid $color-light-gray-2;
    margin-bottom: calc-rem(18px);
    padding-bottom: calc-rem(16px);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    gap: calc-rem(20px);
  }

  &__estimations {
    flex: 1;
    max-width: calc-rem(110px);
  }

  &__title {
    display: flex;
    align-items: center;

    .svg-inline {
      margin-right: calc-rem(8px);
      font-size: calc-rem(22px);
      fill: $color-green-2;
    }

    h6 {
      color: $color-blue-dark-2;
    }
  }

  &__body {
    margin-left: calc-rem(30px);
    color: $color-gray-3;
  }
}

.gm-style-iw-ch {
	font-family: $font-montserrat;
	font-weight: 700;
	font-size: calc-rem(14px);
	color: $color-green-2;
	padding-top: calc-rem(4px);
	
	&r {
		border-bottom: 2px solid $color-light-gray-2;
		margin-bottom: calc-rem(16px);
	}
}

.gm-ui-hover-effect > span {
	margin: calc-rem(9px);
}