ul {
  list-style: none;

  &.list-bullets {
    li {
      padding-left: calc-rem(32px);

      &::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: calc-rem(8px);
        height: calc-rem(8px);
        left: calc-rem(10px);
        top: calc-rem(13px);
        margin-right: calc-rem(15px);
        background-color: $color-primary;
        border-radius: 50%;
      }
    }
  }
}

ol {
  margin-left: calc-rem(14px);
}

.list {
  color: $color-white;

  li {
    position: relative;
    margin: calc-rem(8px) 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
  }
}

.inline-list {
  display: flex;
}

.list-desc {
  &__columns,
  &__rows {
    margin: 0 calc-rem(-20px);

    dt, dd {
      color: $color-white;
      font-size: calc-rem(14px);
      margin: calc-rem(14px) calc-rem(16px);
    }
  
    dt {
      font-weight: 700;
    }
  
    dd {
      font-weight: 400;
    }

  }

  &__columns {
    display: flex;
    flex-wrap: wrap;
  }

  &__row {
    display: flex;
    align-items: center;

    dt, dd {
      flex: 1;
    }
  }

  &__col {
    flex: 1;
  }

  &--double {
    flex: 2
  }

	&--sm {
		dd {
			margin-top: calc-rem(6px);
			margin-bottom: calc-rem(6px);
		}
	}
}

.list__users {
  li {
    display: flex;
    align-items: center;
    margin: calc-rem(12px) 0;
    font-size: calc-rem(14px);
  }

  .svg-inline {
    font-size: calc-rem(14px);
    margin-right: calc-rem(20px);
    fill: $color-primary;
  }
}