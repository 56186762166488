// Checkbox
.input-radio {
  @include radio-checkbox($radiobox-width, $border-radius-circle);
  
	&{
		margin: calc-rem(10px) 0;
	}

  input[type="radio"] + label {
    z-index: 0;

    &::before {
      width: calc-rem($radiobox-width - math.div($radiobox-width, 4));
      height: calc-rem($radiobox-width - math.div($radiobox-width, 4));
      background-color: $color-primary;
      left: calc-rem(2px);
      top: 50%;
      border-radius: $border-radius-circle;
      transform: translateY(-50%) scale(0, 0);
    }
  }

  input[type="radio"] {
    min-width: initial;

    &:checked + label::before {
      transform: translateY(-50%) scale(1, 1);
    }

    &:checked + label::after {
      border-color: $border-color;
    }

    &:disabled + label::after {
      background-color: lighten($color-gray, 26%);
    }
  }
}
